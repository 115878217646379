import React from "react"
import { newsIndex, inner, item, contentWrap, link } from "../styles/components/NewsIndex.module.css"
import Carousel from "./Carousel"

const NewsIndex = ({ newsPosts }) => {
  return (
    <div className={newsIndex}>
      <div className={inner}>
        {newsPosts.map(({ id, newsFields: { images, content, linkUrl, linkText } }) => {
          return (
            <div className={item} key={id}>
              <Carousel images={images} wrapImages={false} compactMobileNav={true} fullWidth={true} />
              {content && <div className={contentWrap} dangerouslySetInnerHTML={{ __html: content }}></div>}
              {linkUrl && linkText && (
                <a className={link} href={linkUrl} target="_blank" rel="noreferrer">
                  {linkText}
                </a>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NewsIndex
