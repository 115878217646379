import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import NewsIndex from "../components/NewsIndex"
import HTMLHead from "../components/HTMLHead"
import { title } from "../styles/components/News.module.css"

const News = ({
  data: {
    allWpPost: { nodes: newsPosts },
  },
}) => {
  return (
    <Layout key="news">
      <h1 className={title}>News Highlights</h1>
      <NewsIndex newsPosts={newsPosts} />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        slug
        newsFields {
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 586, height: 395, quality: 80)
              }
            }
            sourceUrl
          }
          content
          linkUrl
          linkText
        }
      }
    }
  }
`

export default News

export const Head = () => <HTMLHead pageTitle="News" />
